@import "./../comm.scss";

@mixin Item() {
  cursor: grab;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #d0f2ff;
  border-bottom: 2px solid #aadfff;
  border-radius: 12px;
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(-180deg);
  }
}
* {
  user-select: none;
}
.floatDraggableItem {
  @include Item();
  @include Span1();
  background: #74caff;
  padding: 0.8vw 1.4vw 0.4vw 1.4vw;
  text-align: left;
  margin-bottom: 0.6vw;
  transform: translate(0, 0);
  color: #212b36;
  width: 24.5%;
  pointer-events: none;
  opacity: 0.5;
  &.imgType {
    width: 18vw;
    > img {
      width: 70%;
      aspect-ratio: 4/3;
      object-fit: contain;
    }
  }
  .rightImg,
  .wrongImg {
    display: none;
    width: 2vw;
  }
  .audioImg {
    width: 3vw;
  }
}
.topContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 96vw;
  height: 70%;
  padding: 2.5vw 2vw 3vw 2vw;
  box-sizing: border-box;
  gap: 1vw;

  @for $i from 0 through 5 {
    .flipCard_#{$i} {
      background-color: transparent;
      width: 100%;
      height: 100%;
      perspective: 1000px;
      height: 27vw;

      .flipCardInner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        transform: rotateY(0deg);
        animation: flip 0.6s ease-out #{1+ ($i * 0.2)}s forwards;

        .backCard {
          position: absolute;
          width: 100%;
          height: 30vw;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;

          > img {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }

        .zoneBox {
          width: 100%;
          position: absolute;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          flex: 1;
          display: flex;
          flex-direction: column;
          background-color: white;
          border-radius: 1.5vw;
          overflow: hidden;
          transform: rotateY(180deg);
          .header {
            width: 100%;
            min-height: 4.4vw;
            background: #d9fcec;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 1vw;
            color: #212b36;
            > span {
              @include Span2();
            }

            .count {
              width: 2.6vw;
              height: 2.6vw;
              background: #199393;
              border-radius: 50%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              > span {
                @include Span2();
                color: white;
              }
            }
          }

          .dropZone {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            gap: 1vw;
            max-height: 25vw;
            min-height: 25vw;
            height: 25vw;
            padding-left: 1vw;
            padding-right: 2vw;
            overflow-y: scroll;
            padding-top: 1.4vw;
            padding-bottom: 1vw;
            box-sizing: border-box;
            .draggableItem {
              @include Item();
              @include Span2();
              padding: 0.8vw 1.4vw 0.4vw 1.4vw;
              text-align: left;
              transform: translate(0, 0);
              color: #212b36;
              &.imgType {
                width: 18vw;
                > img {
                  width: 70%;
                  aspect-ratio: 4/3;
                  object-fit: contain;
                }
              }
              &.showRightImg {
                .rightImg {
                  display: block;
                }
              }

              &.showWrongImg {
                .wrongImg {
                  display: block;
                }
              }

              .rightImg,
              .wrongImg {
                width: 2.2vw;
                position: absolute;
                right: 0px;
                top: 0px;
                transform: translate(50%, -50%);
              }
              .audioImg {
                width: 3vw;
                height: 2.6vw;
              }
              .rightImg,
              .wrongImg {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.bottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 96vw;
  height: 35%;
  box-sizing: border-box;
  padding: 2.5vw 2vw 3vw 2vw;
  gap: 1vw;
  position: relative;

  .titleBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    position: absolute;
    width: 94vw;
    height: 15vw;
    border-radius: 1vw;
    background-color: rgba(0, 0, 0, 0.421);

    > img {
      width: 6vw;
    }

    .infoBox {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .infoArrow {
        width: 0;
        height: 0;
        border-top: 0.6vw solid transparent;
        border-bottom: 0.6vw solid transparent;
        border-right: 1.4vw solid #1fa7af;
      }

      .infoContent {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1vw 2vw;
        min-width: 10vw;
        height: 2.8vw;
        border-radius: 2vw;
        flex: none;
        order: 1;
        flex-grow: 0;
        background: #1fa7af;

        > span {
          @include Span2();
          color: #ffffff;
        }
      }
    }
  }

  > img {
    width: 2.5vw;
  }

  .dropZone {
    margin: 0 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    gap: 0.4vw;
    max-height: 13vw;
    min-height: 13vw;
    height: 13vw;
    padding-right: 2vw;
    overflow-y: auto;
    scrollbar-color: rgb(199, 198, 198) #00000000;
    flex: 1;
    > .draggableItem {
      @include Item();
      @include Span1();
      padding: 0.8vw 1.4vw 0.4vw 1.4vw;
      text-align: left;
      margin-bottom: 0.6vw;
      transform: translate(0, 0);
      color: #212b36;
      width: 24.5%;
      &.imgType {
        width: 18vw;
        > img {
          width: 70%;
          aspect-ratio: 4/3;
          object-fit: contain;
        }
      }
      .rightImg,
      .wrongImg {
        display: none;
        width: 2vw;
      }
      .audioImg {
        width: 3vw;
      }
      /*  &:hover {
                background: #74CAFF;
                border-bottom: 2px solid #1890FF;
            } */
    }
  }

  .submitPanel {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 98%;
    height: 85%;
    border-radius: 1.6vw;
    pointer-events: none;

    .submit {
      @include Btn1();
      pointer-events: visible;
    }
  }

  .nextStage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    position: absolute;
    width: 94vw;
    height: 15vw;
    border-radius: 1vw;
    background-color: rgba(0, 0, 0, 0.421);
    .nextStageBtn {
      @include Btn1();
    }
  }
}
