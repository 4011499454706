@mixin Span1() {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-size: 2.2vw;
  line-height: 2.8vw;
  text-align: center;
}

@mixin Span2() {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8vw;
  line-height: 2.2vw;
  text-align: center;
}

@mixin Span3() {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 1.5vw;
  text-align: center;
}

@mixin Span4() {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 400;
  font-size: 3.2vw;
  line-height: 3.5vw;
  text-align: center;
}

@mixin CloseBtn() {
  -webkit-tap-highlight-color: transparent;
  border-bottom: 3px solid #0c53b7;
  border-radius: 50%;
  width: 4vw;
  height: 4vw;
  background: #1890ff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: #1870df;
    border-bottom-color: #0c2397;
  }

  > img {
    width: 54%;
  }
}

@mixin Btn1() {
  -webkit-tap-highlight-color: transparent;
  $base-color: #ff7257;
  $base2-color: #b72c2b;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 1vw 2vw;
  background: $base-color;
  border-bottom: 4px solid $base2-color;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    background: darken($base-color, 10%);
    /* 你可以改成你想要的顏色 */
    border-bottom-color: darken($base2-color, 10%);
  }
  &:active {
    border-bottom: 1px solid $base2-color;
  }
  > span {
    @include Span1();
    color: white;
  }
}

@mixin Btn2() {
  -webkit-tap-highlight-color: transparent;
  width: 2.6vw;
  height: 2.6vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  background: #ff7257;
  border-bottom: 3px solid #b72c2b;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: #e6583c;
    border-bottom: 3px solid #b31b1b;
  }
}

@mixin Btn3() {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 1vw 2vw;
  background: #fff6d8;
  border-bottom: 4px solid #ffb73d;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    background: #f9ebbe;
    border-bottom: 4px solid #fbac23;
  }
  &:active {
    border-bottom: 1px solid #ffb73d;
  }
  > span {
    @include Span1();
    color: black;
  }
}

@mixin Btn4() {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 1vw 2vw;
  background: #1890ff;
  border-bottom: 4px solid #0c53b7;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    background: #0987fd;
    border-bottom: 4px solid #073f8e;
  }
  &:active {
    border-bottom: 1px solid #073f8e;
  }
  > span {
    @include Span1();
    color: #ffffff;
  }
}

@mixin Btn5() {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 1vw 2vw;
  background: #b4e8ff;
  border-bottom: 4px solid #1890ff;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    background: #73c9ee;
    border-bottom: 4px solid #0a68c0;
  }
  &:active {
    border-bottom: 1px solid #0a68c0;
  }
  > span {
    @include Span1();
    color: black;
  }
}
