@import "./../comm.scss";

.app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1vw;

  .floatItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 1.2vw 1vw;
    width: 58vw;
    background: #d0f2ff;
    border-bottom: 2px solid #74caff;
    border-radius: 1.4vw;
    transform: translate(0, 0);

    > span {
      @include Span2();
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      /* 限制顯示最多2行 */
      overflow: hidden;
      /* 隱藏多餘的內容 */
      text-overflow: ellipsis;
      /* 添加省略號 */
      word-break: break-all;
      /* 長單字時斷詞 */
      white-space: normal;
      /* 允許換行 */
    }

    /*  &:hover {
            background: #74CAFF;
            border-bottom: 1px solid #1890FF;
        } */
    .audioIcon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 5vw;
      height: 3vw;

      > img {
        width: 70%;
      }
    }

    .rightImg,
    .wrongImg {
      display: none;
      width: 2.2vw;
      position: absolute;
      right: 0px;
      top: 0px;
      transform: translate(50%, -50%);
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 77%;
    height: 84%;
    padding: 3vw 1.4vw;
    box-sizing: border-box;
    position: relative;
    margin-top: 1vw;

    .sortList {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 34.5vw;
      min-height: 34.5vw;
      max-height: 34.5vw;
      box-sizing: border-box;
      overflow-y: scroll;
      padding: 1vw;
      @keyframes flip {
        0% {
          transform: rotateX(0deg);
        }

        100% {
          transform: rotateX(-180deg);
        }
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .flipCard {
          background-color: transparent;
          width: 100%;
          height: 100%;
          perspective: 1000px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &.front {
            .flipCardInner {
              transform: rotateX(-180deg);
            }
          }

          &.showRightImg {
            .flipCardInner {
              .item {
                .rightImg {
                  display: block;
                }
              }
            }
          }

          &.showWrongImg {
            .flipCardInner {
              .item {
                .wrongImg {
                  display: block;
                }
              }
            }
          }

          .flipCardInner {
            position: relative;
            width: 58vw;
            //aspect-ratio: 620/84;
            text-align: center;
            transition: transform 0.6s;
            transform-style: preserve-3d;
            transform: rotateX(0deg);

            .backCard {
              position: absolute;
              width: 100%;
              height: 100%;
              background-size: 100% auto;
              /* 圖片寬度100%，高度自動按比例縮放 */
              background-position: center;
              /* 圖片居中對齊 */
              background-repeat: no-repeat;
              /* 不重複顯示圖片 */
              overflow: hidden;
              /* 裁掉超出div範圍的部分 */
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              border-radius: 1.4vw;
            }

            .item {
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              padding: 1.2vw 1vw;
              width: 100%;
              height: 100%;
              background: #d0f2ff;
              border-bottom: 2px solid #74caff;
              border-radius: 1.4vw;
              transform: translate(0, 0);
              transform: rotateX(180deg);

              > span {
                @include Span2();
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                /* 限制顯示最多2行 */
                overflow: hidden;
                /* 隱藏多餘的內容 */
                text-overflow: ellipsis;
                /* 添加省略號 */
                word-break: break-all;
                /* 長單字時斷詞 */
                white-space: normal;
                /* 允許換行 */
              }

              /*  &:hover {
                                background: #74CAFF;
                                border-bottom: 1px solid #1890FF;
                            } */
              .audioIcon {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 5vw;
                height: 3vw;

                > img {
                  width: 70%;
                }
              }

              .rightImg,
              .wrongImg {
                display: none;
                width: 2.2vw;
                position: absolute;
                right: 0px;
                top: 0px;
                transform: translate(50%, -40%);
              }
            }
          }
        }

        .arrowDown {
          width: 2vw;
          pointer-events: none;
        }
      }
    }

    .tip {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1vw 2vw;
      background: rgba(0, 0, 0, 0.24);
      border-radius: 3vw;
      width: 56vw;
      height: 3.8vw;
      min-height: 3.8vw;
      > span {
        @include Span2();
        color: white;
      }
    }
  }

  .submitBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 5vw;

    .submit {
      @include Btn1();
    }
  }
}
