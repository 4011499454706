@import "./../comm.scss";

.history {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 1.6vw;
  box-sizing: border-box;

  .bg {
    margin-top: 5vw;
    width: 74%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 2vw;

    .title {
      position: absolute;
      top: 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 40px;
      gap: 10px;
      background: linear-gradient(
        90deg,
        #81a4ff 0%,
        #6c79e7 50.5%,
        #4066e3 100.01%
      );
      border-top: 3px solid rgba(255, 255, 255, 0.5);
      box-shadow: 0px 4px 0px #405be7;
      border-radius: 24px;
      transform: translateY(-62%);

      > span {
        @include Span1();
        color: white;
      }
    }

    .content {
      margin-top: 2.5vw;
      width: 81%;
      height: 86%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .item {
        margin: 0.5vw 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 1vw;
        padding-right: 6vw;
        box-sizing: border-box;
        gap: 2vw;
        width: 94%;
        background: #ffffff;
        box-shadow: 0px 4px 0px #7da6f7;
        border-radius: 8px;
        position: relative;

        /*  &:hover {
                    background-color: #ddd;
                } */

        .number {
          @include Span1();
          color: #212b36;
          width: 5vw;
        }

        .icon {
          width: 3%;
          min-width: 3%;
          max-width: 3%;
        }

        .detailBtn {
          position: absolute;
          right: 1vw;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 8px;
          width: 4vw;
          height: 4vw;
          background: #d0f2ff;
          border-bottom: 0.4vw solid #74caff;
          border-radius: 1vw;
          cursor: pointer;

          > img {
            width: 2vw;
          }

          &:hover {
            background: #74caff;
            border-bottom: 0.4vw solid #1890ff;
          }

          &:active {
            height: 3.7vw;
            background: #74caff;
            border-bottom: 0.1vw solid #1890ff;
            transform: translateY(0.3vw);
          }
        }

        .questionText {
          @include Span2();
          text-align: left;
          width: 42%;

          &.scale {
            flex-grow: 1;
          }
        }

        .answerText {
          @include Span2();
          text-align: left;
          width: 42%;
          color: #637381;

          &.scale {
            flex-grow: 1;
          }
        }

        .questionImg,
        .answerImg {
          width: 7vw;
          aspect-ratio: 80/60;
          border-radius: 0.4vw;
          object-fit: contain;
        }
      }
    }
  }

  .backDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5vw;

    .back {
      @include Btn3();
    }
  }
}
