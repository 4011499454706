.app {
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  .container {
    @media screen and (max-aspect-ratio: 1) {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .content {
      display: flex;
      flex-grow: 1;
      width: 100%;
      position: relative;
    }

    .menu {
      height: 8vh;
      z-index: 100;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      background-color: white;
      position: absolute;
      bottom: 0px;
      width: 100%;
    }
  }
}
