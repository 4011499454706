@import "./../comm.scss";

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 61vw;

  .bgani {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  @keyframes slide {
    0% {
      transform: translateY(100%);
    }

    100% {
      transform: translateY(-100%);
    }
  }

  .fire1 {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    animation: slide 30s linear infinite;
  }
  /*  .fire12 {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translateY(100%);
        animation: slide 30s linear infinite;
    }
    .fire3 {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translateY(100%);
        animation: slide 30s linear infinite;
    }
    .fire4 {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translateY(100%);
        animation: slide 30s linear infinite;
    } */
  .closeBtn {
    @include CloseBtn();
    position: absolute;
    right: 1.8vw;
    top: 1.2vw;
  }

  .game {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .headerBar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 1vw 2vw 1vw 2vw;
      align-self: flex-start;
      box-sizing: border-box;
      .left {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.8vw;

        .doneBtn {
          @include CloseBtn();
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 1vw 1.6vw;
          width: 3.4vw;
          height: 2.8vw;
          border-radius: 5vw;
          cursor: pointer;

          > span {
            @include Span2();
            font-size: 1.6vw;
            line-height: 2vw;
            display: flex;
            text-align: center;
            color: #ffffff;
          }
        }

        .cmdBtn {
          @include Btn2();

          > img {
            width: 80%;
          }
        }
      }

      .center {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 15vw;
        height: 4.4vw;
        background: #ffffff;
        border: 2px solid #1890ff;
        border-radius: 3vw;

        > span {
          @include Span1();
          text-align: center;
          color: #1890ff;
        }
      }

      .right {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1vw;

        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-right: 1.2vw;
          gap: 0.8vw;
          background: #fff6d8;
          border-radius: 6vw;
          min-width: 12vw;
          height: 4.4vw;

          &:nth-child(1) {
            width: 9vw;
          }

          > img {
            width: 4.4vw;
            height: 4.4vw;
          }

          > span {
            @include Span1();
            display: flex;
            align-items: center;
            text-align: center;
            color: #7a4f01;
          }
        }
      }
    }
  }
}
