@import "./../comm.scss";

.result {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .resultBg {
    margin-top: 2vw;
    width: 64%;
    height: 66%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    gap: 2vw;

    .rankBox,
    .timeBox {
      box-sizing: border-box;
      width: 24vw;
      height: 12vw;
      background: #d0f2ff;
      border-bottom: 5px solid #74caff;
      border-radius: 20px;

      align-self: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .rank {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        > canvas {
          width: 25%;
        }
      }

      .span1 {
        @include Span1();
        font-size: 6vw;
        color: #54d62c;
        z-index: 1;

        &::before {
          content: attr(data-storke);
          position: absolute;
          color: white;
          -webkit-text-stroke: 0.6vw white;
          text-stroke: 0.6vw white;
          z-index: -1;
        }
      }

      > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        gap: 0.5vw;
        @include Span1();

        .span2 {
          font-size: 5vw;
          line-height: 5vw;
          color: #637381;
          z-index: 1;

          &::before {
            content: attr(data-storke);
            position: absolute;
            color: white;
            -webkit-text-stroke: 0.6vw white;
            text-stroke: 0.6vw white;
            z-index: -1;
          }
        }

        .span3 {
          font-size: 2vw;
          color: #637381;
          z-index: 1;

          &::before {
            content: attr(data-storke);
            position: absolute;
            color: white;
            -webkit-text-stroke: 0.6vw white;
            text-stroke: 0.6vw white;
            z-index: -1;
          }
        }
      }

      .label {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        top: 0px;
        transform: translateY(-50%);

        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.2vw 1.4vw 0.2vw 3.2vw;
          gap: 0.8vw;
          background: #fff6d8;
          border-radius: 6vw;
          position: relative;

          > img {
            position: absolute;
            left: -1.1vw;
            top: -0.6vw;
            width: 4.2vw;
          }

          > span {
            @include Span2();
            font-weight: 700;
            padding: 0.4vw 0;
            display: flex;
            align-items: center;
            text-align: center;
            color: #7a4f01;
          }
        }
      }
    }

    .title {
      position: absolute;
      width: 60vw;
      height: 10vw;
      transform: translateY(-14%);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      > span {
        font-family: Noto Sans TC;
        font-size: 3vw;
        font-weight: 700;
        color: white;
        transform: translateY(-30%);
      }
    }

    .light {
      position: absolute;
      width: 120%;
      transform: translateY(-60%);
      top: 0px;
    }
  }

  .btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 3vw;

    .viewHistory,
    .replay {
      @include Btn3();
    }
  }

  > img {
    position: absolute;
    width: 20vw;
    right: 5vw;
    bottom: 2vw;
  }
}
