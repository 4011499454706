.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.365);
}

.image {
  animation: fadeInOut 1s forwards;
  min-width: 11vw; /* 根據需要調整大小 */
  height: 11vw; /* 根據需要調整大小 */
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
