@import "./../comm.scss";

.AnserDetail {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6vw;
  box-sizing: border-box;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);

  .bg {
    width: 60%;
    aspect-ratio: 600/562;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 1vw;

    .imgType {
      width: 65%;
      aspect-ratio: 540/405;
      background-color: white;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      box-sizing: border-box;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .textType {
      width: 65%;
      aspect-ratio: 540/405;
      background-color: white;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      @include Span4();
      padding: 1rem;
      box-sizing: border-box;
    }

    .voiceType {
      width: 60%;
      aspect-ratio: 540/405;
      background-color: white;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      gap: 1vw;
      @include Span1();
      font-size: 2.8vw;

      .btn {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 12vw;
        aspect-ratio: 148/124;
        background: #ff7257;
        border-bottom: 4px solid #b72c2b;
        border-radius: 12px;

        > img {
          width: 40%;
        }

        &:hover {
          background: #e7573c;
          border-bottom: 4px solid #b72c2b;
        }
        &:active {
          border-top: 3px solid white;
          border-bottom: 1px solid #b72c2b;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 90%;
      border-radius: 1vw;
      overflow: hidden;

      > div > div {
        border-style: solid solid solid solid;
        border-width: 2px;
        border-color: #e4e6e8;
        box-sizing: border-box;
      }

      > div > div:nth-child(1) {
        height: 5vw;
        background-color: #f4f6f8;
        @include Span2();
      }

      > div > div:nth-child(2) {
        height: 8.4vw;
        background-color: white;
        @include Span2();
      }

      .numberBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 16%;
        min-width: 16%;
        > div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      }

      .checkBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 18%;
        min-width: 18%;
        > div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      }

      .myAnswerBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        min-width: 30%;

        > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          &:nth-child(1) {
            padding-left: 2vw;
          }
          &:nth-child(2) {
            overflow-y: auto;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 1vw;
            color: red;
          }
          > span {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .answerBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        min-width: 30%;
        > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          &:nth-child(1) {
            padding-left: 2vw;
          }
          &:nth-child(2) {
            overflow-y: auto;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 1vw;
            color: #229a16;
          }
          > span {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .closeBtnDiv {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 5vw;
      .closeBtn {
        @include Btn1();
        border-radius: 1.4vw;
      }
    }
  }
}
