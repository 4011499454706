@import "./../comm.scss";

@mixin Item() {
  cursor: grab;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #d0f2ff;
  border-bottom: 2px solid #aadfff;
  border-radius: 12px;
}

.history {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 1.6vw;
  box-sizing: border-box;

  .bg {
    margin-top: 4vw;
    width: 80%;
    height: 74%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    gap: 2vw;

    .title {
      position: absolute;
      top: 0px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1vw 4.5vw;
      gap: 1vw;
      background: linear-gradient(90deg, #35bcef 0%, #107bde 100%);
      border-top: 3px solid rgba(255, 255, 255, 0.5);
      box-shadow: 0px 4px 0px #107bde;
      border-radius: 24px;
      transform: translateY(-32%);

      > span {
        @include Span1();
        color: white;
      }
    }

    .tip {
      margin-top: 5vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 8px;
      padding: 0.8vw 1.6vw;
      max-height: 2vw;
      gap: 1vw;
      > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.5vw;

        > img {
          width: 2vw;
        }

        > span {
          @include Span2();
        }
      }
    }

    .content {
      margin-top: 0px;
      width: 94%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 0.2vw;
      padding: 1vw 0 1vw 0;
      box-sizing: border-box;
      height: calc(100% - 12.8vw);

      .itemBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 0vw;
          width: 64vw;
          min-height: 5.2vw;
          background: #f5ffe9;
          border-bottom: 4px solid #a1dc7d;
          border-radius: 12px;
          position: relative;
          @include Span1();
          .audioIcon {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 5vw;
            height: 3vw;
            > img {
              width: 70%;
            }
          }
          .checkImg {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 2vw;
            transform: translate(30%, -30%);
          }
        }
      }

      .arrowDown {
        width: 2vw;
      }
    }
  }

  .backDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5vw;

    .back {
      @include Btn3();
    }
  }
}
