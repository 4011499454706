@import "./../comm.scss";
.pausePanel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.198);
  position: absolute;
  z-index: 100;

  .bg {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20vw;
    width: 34vw;
    background: #e3f0ff;
    border: 0.8vw solid #4066e3;
    box-shadow: 0px 0.6vw 0px #3544c7;
    border-radius: 4vw;
    position: relative;
    padding: 1rem;

    .closeBtn {
      @include CloseBtn();
      position: absolute;
      right: 0px;
      top: 0px;
      transform: translate(40%, -40%);
      width: 4vw;
      height: 4vw;
    }

    > h1 {
      @include Span1();
      color: #0c53b7;
    }

    > span {
      @include Span2();
      color: #212b36;
    }

    .btns {
      padding-top: 2vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      gap: 1vw;

      .undo {
        @include Btn5();
        width: 9vw;
      }

      .continue {
        @include Btn4();
        width: 9vw;
      }
    }
  }
}
