@import "./../comm.scss";

.entrance {
  width: 75%;
  height: 68%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  .titleBox {
    position: absolute;
    top: 0px;
    transform: translateY(-30%);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 3px solid rgba(255, 255, 255, 0.5);
    border-radius: 24px;
    padding: 8px 24px;

    /* >span:nth-child(2) {
            font-family: 'Iansui', sans-serif !important;
        }
        >span:nth-child(3) {
            font-family: 'BpmfZihiOnly', sans-serif !important;
        }
        >span:nth-child(1) {
            font-family: 'BpmfIansui', sans-serif !important;
        } */

    &.game1 {
      background: linear-gradient(
        90deg,
        #81a4ff 0%,
        #6c79e7 50.5%,
        #4066e3 100.01%
      );
      box-shadow: 0px 4px 0px #405be7;
    }

    &.game2 {
      background: linear-gradient(90deg, #4cb892 0%, #1399ac 100.01%);
      box-shadow: 0px 4px 0px #0e928a;
    }

    &.game3 {
      background: linear-gradient(90deg, #35bcef 0%, #107bde 100%);
      box-shadow: 0px 4px 0px #107bde;
    }

    &.game4 {
      background: linear-gradient(90deg, #4cb892 0%, #1399ac 100.01%);
      box-shadow: 0px 4px 0px #0e928a;
    }

    > span {
      @include Span1();
      color: #ffffff;
    }
  }

  .demoBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding-top: 2vw;
    width: 84%;
    gap: 1vw;

    > img:nth-child(1) {
      width: 70%;
    }

    > img:nth-child(2) {
      width: 30%;
      animation: floating 3s ease-in-out infinite;
    }

    @keyframes floating {
      0%,
      100% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-6px);
      }
    }
  }
}

.enterBtn {
  @include Btn1();
  position: absolute;
  bottom: 4%;
}

.loadingPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3vw;
  position: absolute;
  padding: 4.2vw;
  border-radius: 1vw;
  background-color: rgba(0, 0, 0, 0.32);

  > img {
    width: 6vw;
  }

  > span {
    @include Span2();
    color: #ffffff;
  }
}
