@font-face {
  //中文
  font-family: "Iansui";
  src: url("/assets/fonts/Iansui-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  //漢音
  font-family: "BpmfIansui";
  src: url("/assets/fonts/BpmfIansui-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  //注音
  font-family: "BpmfZihiOnly";
  src: url("/assets/fonts/BpmfZihiOnly-R.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html {
  @media (max-width: 599px) {
    font-size: 12px;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    font-size: 14px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 16px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
  }

  @media (min-width: 1200px) {
    font-size: 20px;
  }
  overscroll-behavior: none;
}
body {
  overscroll-behavior: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  outline: none;
}

img,
span {
  pointer-events: none;
  user-select: none;
}
