@import "./../comm.scss";

@mixin Item() {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #d0f2ff;
  border-bottom: 2px solid #aadfff;
  border-radius: 12px;
}

.history {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 1.6vw;
  box-sizing: border-box;

  .bg {
    margin-top: 5vw;
    width: 74%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    gap: 1vw;

    .title {
      position: absolute;
      top: 0px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1vw 4.5vw;
      gap: 1vw;
      background: linear-gradient(90deg, #2cd9c5 0%, #1da091 100%);
      border-top: 3px solid rgba(255, 255, 255, 0.5);
      box-shadow: 0px 0.5vw 0px #199393;
      border-radius: 24px;
      transform: translateY(-62%);

      > span {
        @include Span1();
        color: white;
      }
    }

    .tip {
      margin-top: 3vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 8px;
      padding: 0.8vw 1.6vw;
      max-height: 2vw;
      gap: 1vw;
      > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.5vw;

        > img {
          width: 2vw;
        }

        > span {
          @include Span2();
        }
      }
    }

    .content {
      width: 80%;
      height: 79%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 1vw;
      padding: 0 0 1vw 0;
      box-sizing: border-box;
      overflow-y: auto;

      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.4vw;
        width: 55vw;
        background: #ffffff;
        box-shadow: 0px 4px 0px #36b9a1;
        border-radius: 8px;

        .groupName {
          width: 16%;
          max-width: 16%;
          min-width: 16%;
          height: 100%;
          padding: 1vw;
          box-sizing: border-box;
          background: #d9fcec;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          @include Span1();
        }

        .anser {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;
          flex-grow: 1;
          gap: 1vw;
          padding: 1.6vw 0;

          box-sizing: border-box;
          .option {
            @include Item();
            @include Span2();
            padding: 0.8vw 1.2vw 0.4vw 1.2vw;
            text-align: left;
            position: relative;
            > img {
              object-fit: contain;
            }
            .rightImg,
            .wrongImg {
              position: absolute;
              right: 0px;
              top: 0px;
              width: 2vw;
              transform: translate(30%, -30%);
            }
          }
        }
      }
    }
  }

  .backDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5vw;

    .back {
      @include Btn3();
    }
  }
}
