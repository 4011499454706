@import "./../comm.scss";

.customScrollbar::-webkit-scrollbar {
  width: 0.6vw;
}

.customScrollbar::-webkit-scrollbar-thumb {
  background: #c4cdd5;
  border-radius: 4px;
}

.questionPanel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 94%;
  flex-grow: 1;
  gap: 1vw;
  position: relative;

  .gameBg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 93%;
    gap: 1.6vw;

    .checkAni {
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      > canvas {
        width: 30%;
      }
    }

    .countdown {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 79%;
      gap: 0.8vw;
      > div:nth-child(1) {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.4vw 1vw;
        background: #ffffff;
        border-radius: 10px;
        min-width: 5vw;
        > span {
          @include Span3();
          color: #212b36;
          white-space: nowrap;
        }
      }
      > div:nth-child(2) {
        width: 100%;
        height: 0.6vw;
      }
    }

    .textType {
      width: 80%;
      aspect-ratio: 540/405;
      background-color: white;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      @include Span4();
      padding: 1rem;
      box-sizing: border-box;
    }
    .imgType {
      width: 52vw;
      height: 40vw;
      background-color: white;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 1rem;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .voiceType {
      width: 80%;
      aspect-ratio: 540/405;
      background-color: white;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      gap: 1vw;
      @include Span1();
      font-size: 2.8vw;

      .btn {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 12vw;
        aspect-ratio: 148/124;
        background: #ff7257;
        border-bottom: 4px solid #b72c2b;
        border-radius: 12px;

        > img {
          width: 40%;
        }

        &:hover {
          background: #e7573c;
          border-bottom: 4px solid #b72c2b;
        }

        &:active {
          border-top: 3px solid white;
          border-bottom: 1px solid #b72c2b;
        }
      }
    }
  }

  .selBg {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    height: 93%;
    position: relative;

    .tip {
      margin-top: 2vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0.7vw 1vw;
      width: 70%;
      height: 3;
      background: rgba(0, 0, 0, 0.24);
      border-radius: 30px;

      > span {
        @include Span3();
        color: #ffffff;
      }
    }

    .options {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 88%;
      height: 41vw;
      overflow-y: auto;
      bottom: 1.6vw;
      padding: 1vw 0;
      gap: 0.8vw;

      .option {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1vw 1.6vw;
        width: 21vw;
        background: #d0f2ff;
        border-bottom: 6px solid #74caff !important;
        border-radius: 1.2vw;
        border-width: 0px;
        cursor: pointer;
        opacity: 0;
        transform: scale(0.6);
        animation: growBounce 0.5s forwards;
        -webkit-tap-highlight-color: transparent;

        @for $i from 1 through 8 {
          &:nth-child(#{$i}) {
            animation-delay: #{($i - 1) * 0.05}s;
          }
        }

        > span {
          @include Span2();
          color: #212b36;
        }
        > img {
          object-fit: contain;
        }
        &:hover {
          background: #74caff;
          border-bottom: 6px solid #1890ff !important;
        }

        &:active {
          margin-top: 5px;
          border-bottom: 1px solid #1890ff !important;
        }

        &.correct {
          background: #aaf27f;
          border-bottom: 0.4vw solid #229a16 !important;
        }

        &.wrong {
          background: #ff5e59;
          border-bottom: 0.4vw solid #b72136 !important;
        }
      }

      .optionAudio {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 21vw;
        height: 5vw;
        cursor: pointer;
        opacity: 0;
        transform: scale(0.6);
        animation: growBounce 0.5s forwards;
        gap: 1vw;

        .audioBtn {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 6vw;
          max-width: 6vw;
          height: 5vw;
          background: #fff6d8;
          border-bottom: 6px solid #ffc107;
          border-radius: 12px;

          > img {
            width: 2.2vw;
          }

          &:hover {
            background: #ffdc8a;
          }
          &:active {
            margin-top: 5px;
            border-bottom: 1px solid #ffc107;
          }

          > span {
            @include Span3();
            color: #7a4f01;
            white-space: nowrap;
            font-weight: 700;
          }
        }

        .optionBtn {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 1vw 0.6vw;
          box-sizing: border-box;
          width: 14vw;
          height: 5vw;
          background: #d0f2ff;
          border-bottom: 6px solid #74caff !important;
          border-radius: 1.2vw;
          border-width: 0px;
          cursor: pointer;
          @include Span2();

          &:hover {
            background: #74caff;
            border-bottom: 0.4vw solid #1890ff !important;
          }

          &:active {
            margin-top: 5px;
            border-bottom: 1px solid #1890ff !important;
          }

          &.correct {
            background: #aaf27f;
            border-bottom: 0.4vw solid #229a16 !important;
          }

          &.wrong {
            background: #ff5e59;
            border-bottom: 0.4vw solid #b72136 !important;
          }
        }

        @for $i from 1 through 8 {
          &:nth-child(#{$i}) {
            animation-delay: #{($i - 1) * 0.05}s;
          }
        }

        > span {
          @include Span2();
          color: #212b36;
        }
      }
    }
  }
}

@keyframes growBounce {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(1.02);
    opacity: 1;
  }

  70% {
    transform: scale(0.9);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
