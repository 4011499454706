@import "./../comm.scss";

.start {
  width: 93%;
  height: 93%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  gap: 1.4vw;
  padding-top: 3vw;
  box-sizing: border-box;

  .titleBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;

    > img {
      width: 6vw;
    }

    .infoBox {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .infoArrow {
        width: 0;
        height: 0;
        border-top: 0.6vw solid transparent;
        border-bottom: 0.6vw solid transparent;
      }

      .infoContent {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1vw 2vw;
        min-width: 10vw;
        height: 2.8vw;
        border-radius: 2vw;
        flex: none;
        order: 1;
        flex-grow: 0;

        > span {
          @include Span2();
          color: #ffffff;
        }
      }

      &.game1 {
        .infoArrow {
          border-right: 1.4vw solid #4d91f8;
        }

        .infoContent {
          background: #4d91f8;
        }
      }

      &.game2 {
        .infoArrow {
          border-right: 1.4vw solid #1fa7af;
        }

        .infoContent {
          background: #1fa7af;
        }
      }

      &.game3 {
        .infoArrow {
          border-right: 1.4vw solid #4d91f8;
        }

        .infoContent {
          background: #4d91f8;
        }
      }

      &.game4 {
        .infoArrow {
          border-right: 1.4vw solid #1fa7af;
        }

        .infoContent {
          background: #1fa7af;
        }
      }
    }
  }

  > img {
    width: 68%;
    aspect-ratio: 16/9;
    border-radius: 1vw;
  }

  .startBtnDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5vw;
    position: relative;
    .startBtn {
      @include Btn1();
      position: absolute;
      bottom: 0px;
    }
  }
}
