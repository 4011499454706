@import "./../../../pages/comm.scss";
.questionVoice {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 7.4vw;
  height: 5.3vw;
  background: #ff7257;
  border-bottom: 4px solid #b72c2b;
  border-radius: 0.4vw;
  gap: 0.2vw;

  &:hover {
    background: #e7573c;
    border-bottom: 4px solid #b72c2b;
  }

  &:active {
    background: #e7573c;
    border-top: 3px solid white;
    border-bottom: 1px solid #b72c2b;
  }

  > img {
    width: 30%;
  }

  > span {
    @include Span3();
    font-weight: 500;
    color: white;
  }
}
.answerVoice {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 7.4vw;
  height: 5.3vw;
  background: #fff6d8;
  border-bottom: 4px solid #ffc107;
  border-radius: 12px;
  gap: 0.2vw;
  &:hover {
    background: #ffdc8a;
    border-bottom: 4px solid #ffc107;
  }

  &:active {
    background: #ffdc8a;
    border-top: 3px solid white;
    border-bottom: 1px solid #ffc107;
  }

  > img {
    width: 30%;
  }

  > span {
    @include Span3();
    font-weight: 500;
    color: #7a4f01;
  }
}
