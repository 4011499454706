@import "./../comm.scss";

.history {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 1.6vw;
  box-sizing: border-box;

  .bg {
    margin-top: 5vw;
    width: 92%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 2vw;

    .title {
      position: absolute;
      top: 0px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 40px;
      gap: 10px;
      background: linear-gradient(90deg, #4cb892 0%, #1399ac 100.01%);
      border-top: 3px solid rgba(255, 255, 255, 0.5);
      box-shadow: 0px 4px 0px #0e928a;
      border-radius: 24px;
      transform: translateY(-30%);

      > span {
        @include Span1();
        color: white;
      }
    }

    .panel {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0.8rem 2.2rem 0.8rem 2.6rem;
      gap: 0.6rem;
      box-sizing: border-box;

      .anserPicBox {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 73.3%;
        border-radius: 0.6vw;
        background-color: white;
        margin-top: 0.4vw;
        .anserPic {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .content {
        margin-top: 1vw;
        width: 50%;
        height: 77%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .item {
          margin: 0.5vw 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          padding: 1vw 3vw;
          gap: 1vw;
          width: 82%;
          min-height: 15%;
          max-height: 15%;
          background: #ffffff;
          box-shadow: 0px 4px 0px #81dcc1;
          border-radius: 8px;
          position: relative;

          .number {
            @include Span1();
            color: #212b36;
            width: 5vw;
          }

          .icon {
            width: 4%;
            min-width: 4%;
            max-width: 4%;
          }

          .detailBtn {
            position: absolute;
            right: 1vw;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 8px;
            width: 4vw;
            height: 4vw;
            background: #d0f2ff;
            border-bottom: 0.4vw solid #74caff;
            border-radius: 1vw;
            cursor: pointer;

            &:hover {
              background: #74caff;
              border-bottom: 0.4vw solid #1890ff;
            }

            &:active {
              height: 3.7vw;
              background: #74caff;
              border-bottom: 0.1vw solid #1890ff;
              transform: translateY(0.3vw);
            }
            > img {
              width: 100%;
            }
          }

          .questionText {
            @include Span2();
            text-align: left;
            width: 42%;

            &.scale {
              flex-grow: 1;
            }
          }

          .answerText {
            @include Span2();
            text-align: left;
            width: 42%;
            color: #637381;

            &.scale {
              flex-grow: 1;
            }
          }

          .questionImg,
          .answerImg {
            width: 7vw;
            aspect-ratio: 80/60;
            border-radius: 0.4vw;
            object-fit: contain;
          }
        }
      }
    }
  }

  .backDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5vw;

    .back {
      @include Btn3();
    }
  }
}
